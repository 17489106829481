<template>
  <div class="table-container">
    <VcaTable
      store="user/aspevents"
      :list="getList()"
      :colspan="hasSystemPermission() ? 9 : 8"
      :searchLabel="$t('profile.events.search')"
    >
      <template v-slot:colspan>
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
        <col v-if="hasSystemPermission()" width="10%" />
        <col width="1%" />
      </template>

      <template v-slot:desktop-filter>
        <vca-accordion :header="$t('profile.events.filter')">
          <div slot="body">
            <EventFilter v-model="filter" :events="list" />
          </div>
        </vca-accordion>
      </template>

      <template v-slot:desktop-header>
        <th class="vca-table-cell sortable" @click="sort('name')">
          <label> {{ $t("events.label") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('start_at')">
          <label> {{ $t("events.dates.label") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('location.name')">
          <label> {{ $t("events.location.label") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('crew.name')">
          <label> {{ $t("events.crew.label") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('event_state_text')">
          <label> {{ $t("table.header.state") }} &varr;</label>
        </th>
        <th class="vca-table-cell">
          <label class="sortable" @click="sort('application.start_date')">
            {{ $t("events.application.start_date") }} &varr;</label
          >
          <label class="sortable" @click="sort('application.end_date')">
            {{ $t("events.application.end_date") }} &varr;</label
          >
        </th>
        <th class="vca-table-cell sortable" @click="sort('crew.name')">
          <label>
            {{ $t("events.application.applications") }}
            &varr;</label
          >
        </th>
        <th v-if="hasSystemPermission()" class="vca-table-cell">
          <label>{{ $t("table.header.type") }}</label>
        </th>
        <th class="vca-table-cell">
          <label>{{ $t("table.options") }}</label>
        </th>
      </template>

      <template v-slot:desktop-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in getList()"
          :key="index"
        >
          <td class="vca-table-cell">
            <label>
              {{ res.name }} ({{
                $t("events.eventtypes." + res.type_of_event + ".title")
              }})</label
            >
          </td>
          <td class="vca-table-cell">
            <label> {{ eventPeriod(res) }}</label>
          </td>
          <td class="vca-table-cell">
            <label> {{ res.location.name }}</label>
          </td>
          <td class="vca-table-cell">
            <label v-if="res.crew.name != ''"> {{ res.crew.name }}</label>
            <label v-else> {{ $t("events.list.crew.office_hh") }}</label>
          </td>
          <td class="vca-table-cell">
            <label>
              {{ res.event_state_text }}
              <span v-if="res.event_state.state == 'requested'">
                {{ requestedTo(res) }}
              </span>
            </label>
          </td>
          <td class="vca-table-cell">
            <label>
              {{ date(res.application.start_date) }} -
              {{ date(res.application.end_date) }}</label
            >
          </td>
          <td class="vca-table-cell">
            <label class="table_participations">
              <vca-row
                :class="{
                  green:
                    res.applications.confirmed ==
                    res.application.supporter_count,
                  red:
                    res.applications.confirmed <
                    res.application.supporter_count,
                  orange:
                    res.applications.confirmed >
                    res.application.supporter_count,
                }"
                >{{ res.applications.confirmed }}
                {{ $t("events.participations.status.confirmed") }}</vca-row
              >
              <vca-row
                >{{ res.applications.requested }}
                {{ $t("events.participations.status.requested") }}</vca-row
              >
              <vca-row
                >{{ res.applications.rejected }}
                {{ $t("events.participations.status.rejected") }}</vca-row
              >
              <vca-row
                >{{ res.applications.withdrawn }}
                {{ $t("events.participations.status.withdrawn") }}</vca-row
              >
            </label>
          </td>
          <td v-if="hasSystemPermission()" class="vca-table-cell">
            <label v-if="res.is_internal_asp">
              {{ $t("events.asps.internal")
              }}<span v-if="res.is_event_asp">, </span>
            </label>
            <label v-if="res.is_event_asp">
              {{ $t("events.asps.event") }}
            </label>
          </td>
          <td class="vca-table-cell">
            <label class="table-options">
              <img
                v-for="action in getActionsForEvent(res)"
                :key="action.text"
                :class="action.class"
                :src="action.icon"
                :alt="action.text"
                :title="action.text"
                @click="action.click"
              />
            </label>
          </td>
        </tr>
      </template>

      <template v-slot:mobile-filter>
        <button class="vca-button" @click="do_filter = true">
          {{ $t("profile.events.filter") }}
        </button>
        <vca-popup
          v-if="do_filter"
          :show="do_filter"
          :title="$t('profile.events.filter')"
          @close="do_filter = false"
        >
          <EventFilter v-model="filter" :events="list" />
        </vca-popup>
      </template>

      <template v-slot:mobile-header>
        <div><img width="20px" src="~@/assets/icons/sort.png" /></div>
        <div class="sortable" @click="sort('name')">
          <label>{{ $t("events.label") }} &varr;</label>
        </div>
      </template>

      <template v-slot:mobile-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in getList()"
          :key="index"
        >
          <td class="vca-table-cell">
            <vca-column>
              <vca-row>
                <div class="vca-left vca-table-index" @click="setCurrent(res)">
                  <vca-column>
                    <vca-row
                      ><h3>{{ res.name }}</h3></vca-row
                    >
                    <vca-row>{{ datetime(res.start_at) }}</vca-row>
                    <vca-row>
                      <div class="vca-left vca-table-index">
                        {{ $t("table.header.state") }}:
                        {{ res.event_state_text }}
                      </div>
                    </vca-row>
                    <vca-row>
                      <div class="vca-left vca-table-index">
                        {{ $t("events.application.label") }}:
                        {{ date(res.application.start_date) }} -
                        {{ date(res.application.end_date) }}
                      </div>
                    </vca-row>
                  </vca-column>
                </div>
                <div class="vca-right vca-table-index">
                  <label class="table_participations">
                    <vca-row
                      :class="{
                        green:
                          res.applications.confirmed ==
                          res.application.supporter_count,
                        red:
                          res.applications.confirmed <
                          res.application.supporter_count,
                        orange:
                          res.applications.confirmed >
                          res.application.supporter_count,
                      }"
                      >{{ res.applications.confirmed }}
                      {{
                        $t("events.participations.status.confirmed")
                      }}</vca-row
                    >
                    <vca-row
                      >{{ res.applications.requested }}
                      {{
                        $t("events.participations.status.requested")
                      }}</vca-row
                    >
                    <vca-row
                      >{{ res.applications.rejected }}
                      {{ $t("events.participations.status.rejected") }}</vca-row
                    >
                    <vca-row
                      >{{ res.applications.withdrawn }}
                      {{
                        $t("events.participations.status.withdrawn")
                      }}</vca-row
                    >
                  </label>
                  <div style="margin-left: 8px">
                    <vca-popover
                      :options="getActionsForEvent(res)"
                      key-property="text"
                    >
                      <template v-slot="option">
                        <button
                          v-if="option.visible"
                          class="popover-button"
                          @click="option.click"
                        >
                          <img
                            :class="option.class"
                            :src="option.icon"
                            :alt="option.text"
                          />{{ option.text }}
                        </button>
                      </template>
                    </vca-popover>
                  </div>
                </div>
              </vca-row>
            </vca-column>
          </td>
        </tr>
      </template>
    </VcaTable>
    <vca-popup
      :show="showMap && map.location && map.location.place_id != ''"
      :title="$t('events.map')"
      @close="showMap = false"
    >
      <EventMap v-model="map" />
    </vca-popup>
    <vca-popup
      v-if="currentEvent != null"
      :show="currentEvent != null"
      :title="$t('events.popup.view', { 0: currentEvent.name })"
      @close="currentEvent = null"
    >
      <EventApplication
        :showApplication="false"
        @close="currentEvent = null"
        :event="currentEvent"
      />
    </vca-popup>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import VcaTable from "@/components/utils/VcaTable";
import VcaPopover from "@/components/utils/VcaPopover";
import EventMap from "@/components/events/EventMap";
import EventApplication from "@/components/events/application/EventApplication";
import EventFilter from "@/components/account/events/AccountEventAspFilter";
export default {
  name: "AccountEventAspTable",
  components: { VcaTable, VcaPopover, EventMap, EventFilter, EventApplication },
  data() {
    return {
      currentEvent: null,
      showMap: false,
      map: {},
      store: "user/aspevents/pg",
      do_filter: false,
      currentFilter: {},
      currentTextFilter: "",
    };
  },
  watch: {
    pg: {
      handler(val) {
        this.$store.commit(this.store + "/pagination", val);
      },
      deep: true,
    },
  },
  created() {
    if (Object.keys(this.filter).length == 0) {
      this.filter = {
        crews: [],
        type: [],
        asp_type: [],
        hide_past: true,
      };
    }
  },
  computed: {
    filter: {
      set(value) {
        this.pg.filterObject = value;
      },
      get() {
        return this.pg.filterObject;
      },
    },
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
      list: "user/aspevents/list",
      user: "user/current",
    }),
    emptyFilter() {
      return (
        (!this.pg.filter || this.pg.filter == "") &&
        this.filter.crews.length == 0 &&
        this.filter.type.length == 0 &&
        this.filter.asp_type.length == 0 &&
        this.filter.hide_past == false
      );
    },
    sameFilter() {
      return (
        Object.entries(this.currentFilter).toString() ===
          Object.entries(this.filter).toString() &&
        this.currentTextFilter == this.pg.filter
      );
    },
  },
  methods: {
    eventPeriod(event) {
      return (
        this.datetime(event.start_at) + " - " + this.datetime(event.end_at)
      );
    },
    setMap(value) {
      this.showMap = true;
      this.map = value;
    },
    setCurrent(value) {
      if (this.currentEvent && this.currentEvent.id == value.id) {
        this.currentEvent = null;
      } else {
        this.currentEvent = value;
      }
    },
    setCurrentEdit(value) {
      this.$router.push({ path: "/events/edit/" + value.id });
    },
    setCurrentView(value) {
      this.$router.push({ path: "/events/participants/" + value.id });
    },
    getList() {
      // If the list is empty, we have an empty array
      if (!this.list) {
        return [];
      }

      // If the current page is out of scope, set it to last page
      if (this.pg.currentPage > this.pg.pageCount) {
        this.$store.commit(this.store + "/prevPage");
      }

      // Create a list for filtering and start filtering
      var filteredList = this.list;

      filteredList = filteredList.filter((row, index) => {
        // Add a rank to the current entry
        row.rank = index + 1;

        row.status_translation = this.$t(
          "events.participations.status." + row.status
        );
        if (row.event.type_of_event == "crew_meeting") {
          row.status_translation = this.$t(
            "events.participations.status." +
              row.event.type_of_event +
              "." +
              row.status
          );
        }

        // Map states here
        // published and end_date already passed gets mapped to "finished"
        // no pool or system permissions and state closed gets mapped to "finished"
        row.event_state_text = this.$t(
          "events.list.event_states." + row.mappedState
        );

        if (this.hasSystemPermission() && row.event_state.state == "closed") {
          row.event_state_text = this.$t("events.list.event_states.closed");
        }

        if (row.event_state.state == "requested") {
          row.event_state_text =
            row.event_state.internal_confirmation == ""
              ? this.$t("events.list.event_states.requested_internal")
              : this.$t("events.list.event_states.requested_crew");
        }

        row.is_event_asp = row.event_asp_id == this.user.id;
        row.is_internal_asp = row.internal_asp_id == this.user.id;

        // If the filter is empty, everything is fine
        if (this.emptyFilter) {
          return true;
        }

        if (
          this.filter.crews.length > 0 &&
          !this.filter.crews.find((el) => row.crew.id == el.value)
        ) {
          return false;
        }

        if (
          this.filter.asp_type.length > 0 &&
          !this.filter.asp_type.find(
            (el) =>
              (row.is_internal_asp && el === "internal") ||
              (row.is_event_asp && el === "event")
          )
        ) {
          return false;
        }

        if (
          this.filter.type.length > 0 &&
          !this.filter.type.find((el) => row.event.type_of_event == el)
        ) {
          return false;
        }
        if (
          this.filter.hide_past === true &&
          new Date() > new Date(row.end_at * 1000)
        ) {
          return false;
        }

        // Filter for the current value of row.team_name
        return (
          row.name.toLowerCase().indexOf(this.pg.filter.toLowerCase()) > -1
        );
      });

      // Set the length of the list to the current filtered list
      this.pg.listLength = filteredList.length == 0 ? 1 : filteredList.length;
      if (
        this.pg.listLength < this.pg.pageSize ||
        this.pg.pageSize > filteredList.length
      ) {
        // If the length is smaller than the current page size, we adjust the pagesize to the length of the filtered list
        this.pg.pageSize = this.pg.listLength;
      } else if (!this.sameFilter || this.initial) {
        // If the filter has changed, we resize the list
        this.initial = false;
        this.pg.pageSize =
          this.pg.currentPageSize > this.pg.listLength
            ? this.pg.listLength
            : this.pg.currentPageSize;
      }
      // Set the new filters
      if (!this.sameFilter) {
        this.currentTextFilter = this.pg.filter;
        this.currentFilter = JSON.parse(JSON.stringify(this.filter));
      }

      // Now we filter the list to the current pageSize and paginate it
      return filteredList.filter((row, index) => {
        let start = (this.pg.currentPage - 1) * this.pg.pageSize;
        let end = this.pg.currentPage * this.pg.pageSize;
        if (index >= start && index < end) return true;
      });
    },
    sort(col) {
      // When sorting, we set the current page to 1
      this.$store.commit(this.store + "/firstPage");
      // If we have an empty list, return an empty array
      if (!this.list) {
        return [];
      }
      // Define the sorting direction, if current sort is the same as the column to sort, then switch direction
      if (this.pg.currentSort === col) {
        this.pg.currentSortDir =
          this.pg.currentSortDir === "asc" ? "desc" : "asc";
      } else {
        this.pg.currentSort = col;
      }
      // Sort the list depending on the column and the direction
      this.list.sort((a, b) => {
        var final_key = this.pg.currentSort;
        // Sort nested objects if sorting column contains a dot (.)
        if (col.indexOf(".") !== -1) {
          var column = col.split(".");
          var len = column.length;
          var i = 0;
          while (i < len - 1) {
            a = a[column[i]];
            b = b[column[i]];
            i++;
            final_key = column[i];
          }
        }
        // Set zero 0 at the end
        // if (parseInt(a[final_key]) == 0) return 1;
        // if (parseInt(b[final_key]) == 0) return -1;
        // Comapre integer values
        if (
          a[final_key] === parseInt(a[final_key], 10) ||
          a[final_key] === parseFloat(a[final_key], 10)
        ) {
          return this.pg.currentSortDir === "desc"
            ? a[final_key] - b[final_key]
            : b[final_key] - a[final_key];
        }
        // Compare string values
        let modifier = 1;
        if (this.pg.currentSortDir === "desc") modifier = -1;
        if (a[final_key].toLowerCase() < b[final_key].toLowerCase())
          return -1 * modifier;
        if (a[final_key].toLowerCase() > b[final_key].toLowerCase())
          return 1 * modifier;
        return 0;
      });
    },
    requestedTo(value) {
      return value.event_state.internal_confirmation != ""
        ? this.$t("events.list.event_states.to_crew")
        : this.$t("events.list.event_states.to_office");
    },
    getActionsForEvent(res) {
      return [
        {
          icon: require("@/assets/icons/icon-supporters.png"),
          visible: res.isPublished,
          class: "editable",
          click: () => this.setCurrentView(res),
          text: this.$t("events.participations.header"),
        },
        {
          icon: require("@/assets/icons/edit.png"),
          visible: true,
          class: "editable",
          click: () => this.setCurrentEdit(res),
          text: this.$t("button.edit"),
        },
        {
          icon: require("@/assets/icons/map.png"),
          visible: res.hasLocation,
          class: "editable",
          click: () => this.setMap(res),
          text: this.$t("button.map"),
        },
        {
          icon: require("@/assets/icons/public.svg"),
          visible: true,
          class: "editable apply-filter",
          click: () => this.setCurrent(res),
          text: this.$t("button.view"),
        },
      ].filter((action) => !!action.visible);
    },
  },
};
</script>
<style scoped>
.table-container {
  overflow-x: auto;
}
</style>
